<app-header></app-header>
<app-navbar></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content" style="margin-top: -60px;">
    <!-- START SECTION SHOP -->
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row align-items-center mb-4 pb-1">
                        <div class="col-12" *ngIf="terminoDeBuscar">
                            <div class="ml-3 mr-4 mb-3 mt-0">
                                <button class="btn dash-btn-outline-1 col-12 text-left ml-1 mr-1" id="dash_btn_outline_1" (mouseover)="isHover(true, 'dash_btn_outline_1', '1')" (mouseleave)="isHover(false, 'dash_btn_outline_1', '1')" style="padding-left: 10px;padding-right: 10px;text-transform: none;" (click)="open(filtrado)">Filtrar ofertas<i class="fa fa-filter ml-3" style="float: right; margin-top: .5%;"></i></button>
                            </div>
                        </div>
                        <div class="col-12">
                            <div *ngIf="terminoDeBuscar" class="product_header ml-1 mr-1 row" style="vertical-align: middle;">
                                <div class="col-12 col-md-6">
                                    <!-- <h6 class="widget_title" *ngIf="category_selected.path_from_root.length > 0 && category_selected.children_categories.length === 0" style="margin-bottom: -5px;text-transform: none;">{{category_selected.path_from_root[category_selected.path_from_root.length -1].name}}</h6> -->
                                    <p *ngIf="total_products !== 1">Encontramos {{total_products}} ofertas <b style="font-weight: normal;text-transform: lowercase;" *ngIf="category_selected.path_from_root.length > 0 && category_selected.children_categories.length === 0">de {{category_selected.path_from_root[category_selected.path_from_root.length -1].name}}</b></p>
                                    <p *ngIf="total_products === 1">Encontramos {{total_products}} oferta <b style="font-weight: normal;text-transform: lowercase;" *ngIf="category_selected.path_from_root.length > 0 && category_selected.children_categories.length === 0">de {{category_selected.path_from_root[category_selected.path_from_root.length -1].name}}</b></p>
                                </div>
                                <div class="col-12 col-md-6 mt-md-4">
                                    <div ngbDropdown class="product_color_switch" style="margin-top: -20px;">
                                        <button class="btn dash-btn-outline-1 col-12 text-left d-none d-sm-block" id="dash_btn_outline_2" (mouseover)="isHover(true, 'dash_btn_outline_2', '1')" (mouseleave)="isHover(false, 'dash_btn_outline_2', '1')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">Ordenar por: {{sortOption.text | uppercase}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2%;"></i></button>
                                        <button class="btn dash-btn-outline-1 col-12 text-left d-sm-none" id="dash_btn_outline_2" (mouseover)="isHover(true, 'dash_btn_outline_2', '1')" (mouseleave)="isHover(false, 'dash_btn_outline_2', '1')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;margin-left: 0px;"><i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2.5%;"></i> Ordenar por: <br/>{{sortOption.text | uppercase}}</button>
                                        <div ngbDropdownMenu aria-labelledby="dash_btn_outline_2" style="max-height: 150px;overflow-y: scroll;">
                                            <button class="text-left" ngbDropdownItem *ngFor="let option of sortOptions; let position = index;" [ngClass]="{'bold': option.text === sortOption.text}" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;" (click)="selectSort(option)">{{option.text}}</button>
                                        </div>
                                    </div><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row shop_container">
                        <!-- <div *ngIf="products.length === 0 && terminoDeBuscar === false" class="col-12 text-center mt-2">
                            <img class="img-fluid mb-4" style="width: 30%;" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/loader.gif'">
                        </div> -->
                        <ngx-skeleton-loader
                        *ngIf="products.length === 0 && terminoDeBuscar === false"
                        class="col-12 text-center mt-2"
                            count="2"
                            [theme]="{
                            'border-radius': '5px',
                            height: '50px',
                            'background-color': 'rgba(108, 117, 125, 0.5)',
                            border: '1px solid white'
                            }"
                        ></ngx-skeleton-loader>

                        <div class="col-12 row text-center"
                        *ngIf="products.length === 0 && terminoDeBuscar === false">
                            <div class="col-md-4 col-6 col-lg-3 mt-2" *ngFor="let item of [].constructor(12)">
                                <ngx-skeleton-loader
                            count="1"
                            [theme]="{
                            'border-radius': '5px',
                            'max-width': '250px',
                            'max-height': '250px',
                            'min-height': '250px',
                            'background-color': 'rgba(108, 117, 125, 0.5)',
                            border: '1px solid white'
                            }"
                        ></ngx-skeleton-loader>
                            </div>
                        </div>
                        <div *ngIf="products.length === 0 && terminoDeBuscar" class="col-12 text-center mt-2">
                            <img class="img-fluid mb-4" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/empty-search.jpg'">
                            <h3>¡Lo sentimos! No hemos encontrado productos. </h3>
                            <p>Intente buscar aplicando otros filtros o modificando los actuales</p>
                        </div>
                        <div class="col-md-4 col-6 col-lg-3" *ngFor="let product of products; let position = index;">
                            <div class="product">
                                <span *ngIf="product.is_featured" class="pr_flash">TOP</span>
                                <span *ngIf="product.sale" class="pr_flash bg-danger" [ngClass]="{'mt-4': product.is_featured}">LIQUIDACIÓN</span><br/>
                                <div class="product_img text-center" (click)="showProduct(product)" style="cursor: pointer;max-width: 250px;max-height: 250px;min-height: 250px;" *ngIf="product.attribute_types === 'attributes'">
                                    <a>
                                        <img *ngIf="product.images.length > 0" alt="product_img1" (error)="updateUrl(position, product)" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].url" width="100%" height="auto" max-width="300px">
                                        <img *ngIf="product.images == undefined || product.images.length == 0" alt="product_img1" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" width="100%" height="auto" max-width="300px">
                                    </a>
                                </div>
                                <div class="product_img text-center" (click)="showProduct(product)" style="cursor: pointer;max-width: 250px;max-height: 250px;min-height: 250px;" *ngIf="product.attribute_types === 'variations'">
                                    <a>
                                        <img *ngIf="product.variations.length > 0 && product.variations[0].images[0] !== undefined" alt="product_img1" (error)="updateUrl(position, product)" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.variations[0].images[0].url" width="100%" height="auto" max-width="300px">
                                        <img *ngIf="product.variations.length > 0 && product.variations[0].images[0] === undefined" alt="product_img1" (error)="updateUrl(position, product)" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" width="100%" height="auto" max-width="300px">
                                        <img *ngIf="product.variations == undefined || product.variations.length == 0" alt="product_img1" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" width="100%" height="auto" max-width="300px">
                                    </a>
                                </div>
                                <!-- <div class="product_action_box">
                                    <ul class="list_none pr_action_btn">
                                        <li><a (click)="showQuickView(product)" class="popup-ajax" style="cursor: pointer;" id="quick_view_{{position}}" (mouseover)="isHover(true, 'quick_view_'+position, '2')" (mouseleave)="isHover(false, 'quick_view_'+position, '2')"><i class="icon-magnifier-add"></i></a></li>
                                    </ul>
                                </div> -->
                                <div class="product_info">
                                  <div *ngIf="product.attribute_types === 'attributes' && product.stock === 0" class="mb-2 text-center">
                                    <span class="price badge badge-danger text-white" style="font-size: 90%;">No disponible</span>
                                </div>
                                    <h6 class="product_title text-center"><a (click)="showProduct(product)">{{product.title}}</a></h6>
                                    <div *ngIf="product.has_discount" class="product_price text-center">
                                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
                                        <del>{{product.retail_price | currency}} {{product.currency}}</del>
                                        <div class="on_sale">
                                            <span>{{product.discount}}% descuento</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!product.has_discount" class="product_price text-center">
                                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price | currency}} {{product.currency}}</span>
                                    </div>
                                    <div class="quantity mt-2" style="margin: 0 auto;" *ngIf="product.attribute_types === 'attributes' && product.stock !== null &&  product.stock >0">
                                      <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', product)" [disabled]="product.counter <= 1">
                                      <input (keyup)="listenQuantity($event, product)" type="text" name="quantity" value="{{product.counter}}" title="Qty" class="qty" max="{{product.stock}}" min="1">
                                      <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', product)" [disabled]="product.counter >= product.stock">
                                  </div>
                                  <button *ngIf="product.attribute_types === 'attributes' && product.stock !== null && product.stock <=0" style="text-transform: uppercase;font-size: 100%;margin:0 auto;display: block;" class="btn pt-2 pb-2 pl-3 pr-3 mt-3 rounded-pill" type="button" (click)="showPreorderModal(product)" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-bell"></i> ¡Notificarme!</button>
                                  <button *ngIf="product.attribute_types === 'attributes' && product.stock !== null && product.stock >0" style="text-transform: uppercase;font-size: 100%;margin:0 auto;display: block;" class="btn pt-2 pb-2 pl-3 pr-3 mt-2 rounded-pill" type="button" (click)="addToCart(product)" id="add_cart" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-basket-loaded"></i> Agregar</button>
                                    <!-- <div class="pr_switch_wrap">
                                    <div class="product_color_switch">
                                        <span class="active" data-color="#87554B"></span>
                                        <span data-color="#333333"></span>
                                        <span data-color="#DA323F"></span>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center mt-5 mb-2 noselect">
                            <ul class="ui-pagination" *ngIf="products.length > 0 && total_pages > 1">
                                <li class="mr-1 mr-md-4 text-on-step" *ngIf="prev_page !== null" (click)="navOnPagination('back')" style="cursor: pointer;"><i class="fa fa-chevron-left mr-1 icon-prev-next"></i>Anterior</li>
                                <li class="ml-1 mr-1"><span class="badge current-step pt-1 pb-1">{{current_page}}</span></li>
                                <li class="text-on-step">de {{total_pages}}</li>
                                <li class="ml-2 ml-md-4 text-on-step" *ngIf="next_page !== null" (click)="navOnPagination('next')" style="cursor: pointer;">Siguiente<i class="fa fa-chevron-right ml-1 icon-prev-next"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 order-lg-first mt-4 pt-2 mt-lg-0 pt-lg-0">
                    <div class="sidebar d-none d-lg-block">
                        <div class="widget row">
                            <div class="col-md-12 mt-md-5" [ngClass]="{'col-12': category_selected.children_categories.length === 0, 'col-6': category_selected.children_categories.length > 0}">
                                <h5 class="widget_title">Departamentos</h5>
                                <ul class="list_brand" style="overflow-y: scroll;">
                                    <li *ngFor="let department of departments; let position = index;">
                                        <div class="custome-checkbox">
                                            <input class="form-check-input" type="checkbox" name="checkbox" id="{{position}}" value="position" [checked]="department_selected.slug === department.slug" (click)="selectCollection(department, position)">
                                            <label class="form-check-label" for="{{position}}"><span>{{department.name | uppercase}}</span></label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->

</div>
<ng-template #filtrado let-modal>
    <div class="modal-header text-center" style="margin-bottom: -15px;vertical-align: middle;">
        <h5 class="modal-title" id="modal-basic-title" style="font-size: 130%;"><b>OPCIONES DE FILTRADO</b></h5>
        <a (click)="modal.dismiss('Cross click')"><i class="fa fa-times" style="cursor: pointer;"></i></a>
    </div>
    <div class="modal-body" class="col-12 pt-0" style="background: white;">
        <div class="widget row">
            <!-- <div class="col-12">
                <div class="col-12 text-left mt-2 noselect" *ngIf="category_selected.path_from_root.length > 0">
                    <span *ngFor="let path_root of category_selected.path_from_root; let position = index;"><b class="badge rounded-pill badge-category ml-1 mr-1 p-1 mb-1" style="cursor: pointer;" (click)="regresarDeNivel(position)">{{path_root.name}}</b> <i class="fa fa-chevron-right ml-1 mr-1 badge-category-icon" style="font-size: 90%;" *ngIf="(category_selected.path_from_root.length - 1 ) !== position" [ngStyle]="{'color': colorTheme2}"></i></span>
                </div>
                <br/>
            </div> -->
            <!-- <div class="col-12 mt-0 ml-md-2 mb-2" *ngIf="category_selected.children_categories.length > 0">
                <h5 class="widget_title">Categorías</h5>
                <ul class="widget_categories" style="max-height: 200px;overflow-y: scroll;margin-top: -20px;" [hidden]="category_selected.children_categories.length === 0">
                    <li *ngFor="let category of category_selected.children_categories; let position = index;" style="cursor: pointer;" (click)="selectCategory(category)" id="category_0{{position}}">
                        <span class="text-category3 col-10 mb-2 mt-2 mr-4" style="float: left;top: 20%;text-transform: none;">
                            {{category.name}}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="col-12 mt-0 ml-md-2 mb-2" *ngIf="category_selected.children_categories.length === 0 && category_selected.uid === ''">
                <h5 class="widget_title">Categorías</h5>
                <ul class="widget_categories" style="max-height: 200px;overflow-y: scroll;margin-top: -20px;">
                    <li *ngFor="let category of categories2; let position = index;" style="cursor: pointer;" (click)="selectCategory(category)" id="category_0{{position}}">
                        <span class="text-category3 col-10 mb-2 mt-2 mr-4" style="float: left;top: 20%;text-transform: none;">
                            {{category.name}}
                        </span>
                    </li>
                </ul>
            </div> -->
            <div class="col-12 mb-4 ml-md-2" [ngClass]="{'mt-3': category_selected.children_categories.length === 0 && category_selected.uid === '' || category_selected.children_categories.length > 0, 'mt-0': category_selected.children_categories.length === 0 && category_selected.uid !== '', 'mt-md-3': category_selected.children_categories.length === 0 && category_selected.uid === '' || category_selected.children_categories.length > 0, 'mt-md-0': category_selected.children_categories.length === 0 && category_selected.uid !== ''}">
                <h5 class="widget_title">Departamentos</h5>
                <ul class="list_brand" style="max-height: 200px;overflow-y: scroll;margin-top: -20px;">
                    <li *ngFor="let department of departments; let position = index;">
                        <div class="custome-checkbox">
                            <input class="form-check-input" type="checkbox" name="checkbox" id="{{position}}" value="position" [checked]="department_selected.slug === department.slug" (click)="selectCollection(department, position)">
                            <label class="form-check-label" for="{{position}}"><span>{{department.name | uppercase}}</span></label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer bg-btn-filter col-12">
        <button type="button" class="btn btn-pedidos align-center col-12 text-white" (click)="modal.close()" style="margin-top: -15px;margin-bottom: -15px;"><b>APLICAR FILTROS</b></button>
    </div>
</ng-template>
<!-- END MAIN CONTENT -->
<app-footer></app-footer>
